
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    chain: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
    baseCoin: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    fastConfirms: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    safeConfirms: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    gasTrackerApi: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    chainList: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
    hasToken: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
}
export default{
    mainRules
}